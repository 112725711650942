import React, { useEffect, useState , useRef } from 'react';
import { Link , useNavigate} from 'react-router-dom';
import axios from 'axios';

import '../Styles/Events.css'

import Footer from '../Containers/Footer';
import HeaderBar from '../Containers/HeaderBar';
import ClearBackground from '../Components/ClearBackground';
import CardSafari from '../Containers/CardSafari';


const Events = () => {
    const navigate = useNavigate();
    const howItWorksRef = useRef(null);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchEvents = async () => {
        try {
            const response = await axios.get('https://articles-tr7ecrkimq-uc.a.run.app/events');
            setEvents(response.data);
        } catch (error) {
            console.error('Error fetching events', error);
        } finally {
            setLoading(false); // Termina el estado de carga después de la respuesta
        }
        };
        fetchEvents();
    }, []);

    const handleScrollHowItWorks = () => {
        if (howItWorksRef.current) {
        howItWorksRef.current.scrollIntoView({ behavior: 'smooth' });

        setTimeout(() => {
            window.scrollBy({ top: -200, behavior: 'smooth' }); 
            }, 1000); 
        }
    };
    if (loading) {
        return <div></div>; 
    }

    return (
        <div className='events' >
            <div className='home-header'>
                <HeaderBar onScrollHowItWorks={handleScrollHowItWorks} />
            </div>
            <div className='parent-container-event'>
                <div className="event-list">
                    <h1>Próximos Eventos</h1>
                    <div className="event-grid">
                        {events.slice().reverse().map(event => (
                        <div key={event.event_id} className="event-item">
                            <Link to={`/event/${event.event_id}`}>
                                <div className="eventos">
                                    <div className="event-img-container">
                                        <img src={event.event_img} alt={event.event_name} />
                                    </div>
                                    <div className="event-info">
                                        <h2>{event.event_name}</h2>
                                        <p>📍{event.location}</p>
                                        <p><span className="event-icon">📅</span> {event.date_formatted}</p>
                                        
                                    </div>
                                </div>
                            </Link>
                        </div>
                        ))}
                    </div>
                </div>      
            </div>
            <div ref={howItWorksRef} >
                <ClearBackground >
                    <CardSafari mode="light" />
                </ClearBackground>
            </div>
            <Footer />
        </div>
    );
};

export default Events;