import React, { useState } from 'react';

const ContactFormCorp = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    tripType: 'private', // 'private' o 'business'
    email: '',
    serviceDate: '',
    passengers: '',
    comments: ''
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const today = new Date().toISOString().split('T')[0];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch('https://articles-tr7ecrkimq-uc.a.run.app/contact_form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Error al enviar el formulario');
      }

      setSuccess(true);
      setFormData({
        fullName: '',
        phone: '',
        tripType: 'Privado',
        email: '',
        serviceDate: '',
        passengers: '',
        comments: ''
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="contact-container">
      {/* Columna izquierda - Información de contacto */}
      <div className="contact-info">
        <div className="info-section">
          <h2>Contáctanos</h2>
        </div>
        
        <div className="info-section">
          <h3>🚗Adaptamos cada Traslado a tus Necesidades</h3>
          <p>Adaptamos cada viaje a tu evento, con opciones de vehículos y horarios flexibles.</p>
        </div>
        
        <div className="info-section">
          <h3>📍Puntos de Recogida Personalizados</h3>
          <p>Planeamos recogidas y destinos con sus horarios para que todos lleguen sin estrés.</p>
        </div>

        <div className="info-section">
          <h3>👥Coordinación Fácil para Grupos</h3>
          <p>Organiza el transporte de todo tu grupo en un solo lugar, sin complicaciones ni llamadas extra.</p>
        </div>

        <div className="info-section">
          <h3>🏢De Bodas a Eventos de Empresa: Te Llevamos</h3>
          <p>Ideal para matrimonios, eventos corporativos, festivales y celebraciones privadas.</p>
        </div>
      </div>

      {/* Columna derecha - Formulario */}
      <div className="form-container">
      <form onSubmit={handleContactSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="fullName">Nombre completo</label>
              <input 
                type="text" 
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                placeholder="Juanito Pérez"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Teléfono de contacto</label>
              <input 
                type="tel" 
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="+56 9 12345678"
                required
              />
            </div>
          </div>



          <div className="form-row">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input 
                type="email" 
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="hola@email.com"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="serviceDate">Fecha del servicio</label>
              <input 
                type="date" 
                id="serviceDate"
                name="serviceDate"
                value={formData.serviceDate}
                onChange={handleChange}
                min={today}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="passengers">Número de pasajeros aprox</label>
              <input 
                type="number" 
                id="passengers"
                name="passengers"
                value={formData.passengers}
                onChange={handleChange}
                placeholder="0"
                min="1"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="tripType">Tipo de traslado</label>
              <select
                id="tripType"
                name="tripType"
                value={formData.tripType}
                onChange={handleChange}
                required
              >
                <option value="private">Particular</option>
                <option value="business">Empresa</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="comments">Comentarios adicionales</label>
            <textarea 
              id="comments"
              name="comments"
              value={formData.comments}
              onChange={handleChange}
              placeholder="Escribe aquí cualquier información adicional"
            ></textarea>
          </div>

          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">¡Formulario enviado con éxito!</div>}

          <button type="submit" disabled={loading}>
            {loading ? 'Enviando...' : 'Enviar'}
          </button>
        </form>
      </div>
      
      <style jsx>{`
        .contact-container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 2rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 3rem;
        }

        .contact-info {
          padding: 1rem;
        }

        .info-section {
          margin-bottom: 2rem;
        }

        .info-section h2 {
          font-size: 2rem;
          margin-bottom: 1rem;
          color: var(--blue);
        }

        .info-section h3 {
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
        }

        .info-section p {
          line-height: 1.6;
        }

        .form-container {
          background: white;
          padding: 2rem;
          border-radius: 8px;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.207);
        }

        .form-row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
          margin-bottom: 1.5rem;
        }

        .form-group {
          margin-bottom: 1.5rem;
        }

        .form-row .form-group {
          margin-bottom: 0;
        }

        .form-group label {
          display: block;
          margin-bottom: 0.5rem;
          color: black;
          font-weight: 500;
        }

        .form-group input[type="text"],
        .form-group input[type="tel"],
        .form-group input[type="email"],
        .form-group input[type="date"],
        .form-group input[type="number"],
        .form-group textarea,
        .form-group select {
          width: 100%;
          padding: 0.75rem;
          border: 1px solid #ddd;
          border-radius: 5px;
          font-size: 1rem;
          background-color: white;
        }

        .form-group select {
          cursor: pointer;
        }

        .form-group textarea {
          min-height: 50px;
          resize: vertical;
        }

        button {
          width: 100%;
          padding: 1rem;
          background-color: var(--blue);
          color: white;
          border: none;
          border-radius: 4px;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        button:hover {
          background-color: #1d4ed8;
        }

        button:disabled {
          background-color: #93c5fd;
          cursor: not-allowed;
        }

        .privacy-notice {
          text-align: center;
          font-size: 0.875rem;
          color: #666;
          margin-top: 1rem;
        }

        .error-message {
          color: #dc2626;
          background-color: #fee2e2;
          padding: 0.75rem;
          border-radius: 4px;
          margin-bottom: 1rem;
        }

        .success-message {
          color: #059669;
          background-color: #d1fae5;
          padding: 0.75rem;
          border-radius: 4px;
          margin-bottom: 1rem;
        }

        @media (max-width: 1000px) {
          .contact-container {
            grid-template-columns: 1fr;
            gap: 2rem;
          }

          .form-row {
            grid-template-columns: 1fr;
          }
        }

        input:focus,
        textarea:focus,
        select:focus {
          outline: none;
          border-color: var(--blue);
          box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
        }
      `}</style>
    </div>
  );
};

export default ContactFormCorp;