import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from '../Pages/Home.jsx';
import Events from '../Pages/Events.jsx';
import EventDetail from '../Pages/EventDetail.jsx';
import Checkout from '../Pages/Checkout.jsx';
import PrivateEvent from '../Pages/PrivateEvent.jsx';

import '../Styles/App.css';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home/>}  />
        <Route exact path='/events' element={<Events/>}  />
        <Route path='/event/:event_id' element={<EventDetail/>} />
        <Route exact path='/checkout' element={<Checkout/>}  />
        <Route exact path='/traslado-privado' element={<PrivateEvent/>}  />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
