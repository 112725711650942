import React from 'react'
import styled from 'styled-components';

const Card =styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom:6rem;

`

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    width: 70vw;
    height: 25vw;
    border-radius: 2rem;
    flex-direction: ${({ textOn }) => (textOn === 'left' ? 'row' : 'row-reverse')};
    @media only screen and (max-width: 1050px) {
        flex-direction: column; /* Cambia la dirección del flexbox para colocar elementos uno encima del otro */
        align-items: flex-start; /* Alinea los elementos a la izquierda */
        height: 100%;
    }
`;

const ImageContainer = styled.div`
    flex: 1;
    padding: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TextContainer = styled.div`
    flex: 1;
    padding: 20px;
    height: 100%;
    overflow: hidden;
    display:flex;
    width: 100%;
    flex-direction: column;  
    justify-content:center;

    & > p {
    margin-top: 1rem;
    }
    & > ul {
    margin-top: 1.5rem;
    margin-left: 1rem;
    }

`;

const Image = styled.img`
    max-width: 100%;
    height: 100%;
    display: block;
`;


const CardBasic = ({imageUrl, imageAlt, children, textOn}) => {
  return (
    <Card>
    <Container textOn={textOn}>
      <TextContainer>
        {children}
      </TextContainer>
      
      <ImageContainer>
        <Image src={imageUrl} alt={imageAlt} />
      </ImageContainer>
    </Container>
  </Card>
  )
}

export default CardBasic