import React from 'react'
import styled from 'styled-components'

const Card = styled.div`
    max-height: 90%;
    margin: 0 auto;
    background-color:${(props) => (props.mode ==="light" ? '#ffffff' : '#3c3d3f')} ;
    border-radius: 8px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.12);
    z-index: 1;

    @media only screen and (max-width: 820px) {
    width: 80%;
  }
`

const Tools = styled.div`
  display: flex;
  align-items: center;
  padding: 9px;
`;

const Circle = styled.div`
  padding: 0 4px;
`;

const Box = styled.div`
  display: inline-block;
  align-items: center;
  width: 10px;
  height: 10px;
  padding: 1px;
  border-radius: 50%;
  ${({ color }) => color && `background-color: ${color};`}
`;

const RedBox = styled(Box)`
  background-color: #ff605c;
`;

const YellowBox = styled(Box)`
  background-color: #ffbd44;
`;

const GreenBox = styled(Box)`
  background-color: #00ca4e;
`;

const HowItImage = styled.img`
height: 90%;
max-height: 30rem;
max-width: 100%;
padding:4px;
`
const ImgContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
width: 100%;
`

const CardSafari = ({mode}) => {
  return (
    <Card mode={mode}>
      <Tools>
        <Circle>
          <RedBox />
        </Circle>
        <Circle>
          <YellowBox />
        </Circle>
        <Circle>
          <GreenBox />
        </Circle>
      </Tools>
      <ImgContainer>
        <h3 style={{"font-size":"1.6rem"}} >¿Cómo funciona?</h3>
        <HowItImage src="https://storage.googleapis.com/eway-back.appspot.com/movy/Flujo%20Movy%20-%20Como%20funciona%20(2).gif" alt="movy fig of how it works" />
      </ImgContainer>
      
    </Card>
  )
}

export default CardSafari