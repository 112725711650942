import React, { useRef } from 'react'
import {  useNavigate} from 'react-router-dom';

import PrimaryButton from '../Components/PrimaryButton';
import HeaderBar from '../Containers/HeaderBar';

import ContactFormCorp from '../Containers/ContactFormCorp';
import Footer from '../Containers/Footer';


const PrivateEvent = () => {
    const navigate = useNavigate();

    const howItWorksRef = useRef(null);


    const handleScrollContactUs = () => {
        if (howItWorksRef.current) {
        howItWorksRef.current.scrollIntoView({ behavior: 'smooth' });

        setTimeout(() => {
            window.scrollBy({ top: -200, behavior: 'smooth' }); 
            }, 1000); 
        }
    };

    const navigateToEvents = () => {
        navigate('/events');
    };
        
    return (
    <body>
        <div className='home-header'>
            <HeaderBar onScrollHowItWorks={handleScrollContactUs} />
        </div>

        <section id="main-section-short" >
            <div className="main-section-text">
                <h1> <span>Olvídate de la coordinación</span> <br /> Nosotros lo organizamos todo</h1>
                <p className ="main-p">
                Nos encargamos de toda la logística para que tú no tengas que preocuparte por organizar horarios ni vehículos.
                </p>

                
            </div>
            <div >
                {/*<img src="https://firebasestorage.googleapis.com/v0/b/eway-d5238.appspot.com/o/van_hero.png?alt=media&token=57566a79-aa2d-4a79-84a3-1de34a424ff0" alt="van with 8 people inside going to a concert" className='hero-image' />*/}
            </div>
        </section>


        <div>
            <ContactFormCorp />
        </div>
        <Footer />
    </body>
    );
};


export default PrivateEvent