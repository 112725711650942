import React, { useRef } from 'react'
import {  useNavigate} from 'react-router-dom';
import '../Styles/home.css'

import PrimaryButton from '../Components/PrimaryButton';
import HeaderBar from '../Containers/HeaderBar';

import ClearBackground from '../Components/ClearBackground';
import CardSafari from '../Containers/CardSafari';
import Footer from '../Containers/Footer';
import CardBasic from '../Containers/CardBasic';



const Home = () => {
    const navigate = useNavigate();

    const howItWorksRef = useRef(null);


    const handleScrollHowItWorks = () => {
        if (howItWorksRef.current) {
        howItWorksRef.current.scrollIntoView({ behavior: 'smooth' });

        setTimeout(() => {
            window.scrollBy({ top: -200, behavior: 'smooth' }); 
            }, 1000); 
        }
    };

    const navigateToEvents = () => {
        navigate('/events');
    };

    const navigateToPrivate = () => {
        navigate('/traslado-privado');
    };
        
    return (
    <body>
        <div className='home-header'>
            <HeaderBar onScrollHowItWorks={handleScrollHowItWorks} />
        </div>

        <section id="main-section">
            <div className="main-section-text">
                <h1> <span>Disfruta el Show</span> <br />Nosotros Nos Encargamos del Viaje</h1>
                <p className ="main-p">
                Nuestro servicio puerta a puerta te lleva y te trae de vuelta sin complicaciones
                </p>
                <div className="main-button">
                    {<PrimaryButton text="Eventos✨" height="3rem" width="15rem" onClick={navigateToEvents} />}
                    {<PrimaryButton text="Traslados Especiales" height="3rem" width="15rem" onClick={navigateToPrivate} mode='light' />}
                </div>
                
            </div>
            <div >
                <img src="https://firebasestorage.googleapis.com/v0/b/eway-d5238.appspot.com/o/van_hero.png?alt=media&token=57566a79-aa2d-4a79-84a3-1de34a424ff0" alt="van with 8 people inside going to a concert" className='hero-image' />
            </div>
        </section>

        <div ref={howItWorksRef} >
            <ClearBackground >
                <CardSafari mode="light" />
            </ClearBackground>
        </div>

        <CardBasic
            imageUrl="https://firebasestorage.googleapis.com/v0/b/eway-d5238.appspot.com/o/van_concert.png?alt=media&token=c0b939ac-f828-4447-84ee-83ba7bcc09e1" 
            imageAlt="ilsutracion de personas muy felices sobre una van">
            <h1>Olvídate del estrés de último minuto</h1>            
            <p>
                Reserva con anticipación y recibe confirmación inmediata. Tu conductor asignado estará esperando en el momento y lugar acordados
            </p>
            <ul>
                <li>Confirmación instantánea y recordatorios previos al evento para tu tranquilidad</li>
                <li>Conductores monitoreados en tiempo real con GPS para que sepas exactamente cuándo llegará</li>
            </ul>
        </CardBasic>

        <CardBasic
            imageUrl="https://firebasestorage.googleapis.com/v0/b/eway-d5238.appspot.com/o/van_and_cars.png?alt=media&token=f374980a-a30e-4320-903d-6fae723c5bc9" 
            imageAlt="una van por delante de dos vehiculos"
            textOn="left">
            <h1>No más precios impredecibles</h1>
            <p>
                Conoce el costo exacto al momento de reservar, sin importar la demanda durante el evento
            </p>
            <ul>
                <li>Tarifa fija garantizada desde el momento de tu reserva</li>
                <li>Sin cobros sorpresa por alta demanda o tráfico</li>
            </ul>
        </CardBasic>

        <CardBasic
            imageUrl="https://firebasestorage.googleapis.com/v0/b/eway-d5238.appspot.com/o/just_a_van.png?alt=media&token=584e514d-db49-471f-8b7a-c96c95ac5503" 
            imageAlt="ilustracion de una van celeste">
            <h1>Di adiós a la pesadilla del estacionamiento</h1>
            <p>
                Olvídate de las vueltas interminables buscando lugar. Te dejamos y te buscamos directamente en la entrada del evento
            </p>
            <ul>
                <li>Ahorra hasta 45 minutos que gastarías buscando y yéndote estacionamiento</li>
                <li>Evita los costosos estacionamientos y las largas caminatas</li>
            </ul>
        </CardBasic>

        <CardBasic
            imageUrl="https://firebasestorage.googleapis.com/v0/b/eway-d5238.appspot.com/o/van_main_stage.png?alt=media&token=50a2f1e8-4e9e-4448-aacf-76d7da809450" 
            imageAlt="van en un concierto junto con una banda"
            textOn="left">
            <h1>Coordinación grupal sin complicaciones</h1>
            <p>
                Un solo lugar para organizar el transporte de todo tu grupo, sin necesidad de múltiples llamadas, mensajes o vehículos
            </p>
            <ul>
                <li>Descuentos especiales para grupos</li>
                <li>Sistema de notificaciones para mantener a todos informados sobre cambios</li>
            </ul>
        </CardBasic>

        <Footer />
    </body>
    );
};


export default Home