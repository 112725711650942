import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import PrimaryButton from '../Components/PrimaryButton';

const HeaderMenu = styled.div`
    @media only screen and (min-width: 820px) {
    display: none;
  }
  `
const LogoImg = styled.img`
max-width: 100%;
height: 1.5rem;
margin-top: 10px;
`;

const HamburgerDiv = styled.div`
  cursor: pointer;
  position:fixed;
  top: 1.2rem;
  left: 1.5rem;
  width: 100%;
  padding-right: 3.5rem;
  display:flex;
  justify-content: flex-start;
  align-items:center;

  & > :nth-child(1),
  & > :nth-child(2) {
    margin-right: 5%; 
  }
  & > :nth-child(3) {
    position:fixed;
    right:5%;
  }
`;

const MenuContainer = styled.div`
  position: fixed;
  top: ${props => (props.isOpen ? '4.5rem' : '100vh' )};
  right: 0;
  height: auto;
  width: 90vw;
  background-color: rgb(255, 255, 255, 0.993);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.35);
  border-radius:2rem ;
  transition: top 0.3s ease-in-out; 
`;

const MenuList = styled.ul`
  list-style-type: none;
  padding: 20px;
`;

const MenuItem = styled.li`
  padding: 20px 0;
  list-style-type: none;
  cursor: pointer;
  
  &:hover {
    font-weight: bold;
  }
`;

const HamburgerMenu = ({onScrollHowItWorksMobile}) => {

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);


  const navigateToEvents = () => {
    navigate('/events');
  };
  const navigateToHome = () => {
    navigate('/');
  };
  

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderMenu>
      <HamburgerDiv >
        <span onClick={toggleMenu} class="material-symbols-outlined">menu</span>
        <LogoImg src="/movy_logo.svg" alt="movy logo" onClick={navigateToHome}  />
        <PrimaryButton onClick={navigateToEvents} text="Eventos" />
      </HamburgerDiv>
      <MenuContainer isOpen={isOpen}>
        <MenuList>
          <MenuItem onClick={onScrollHowItWorksMobile} >
            Como Funciona
          </MenuItem>
          <MenuItem>
            <PrimaryButton onClick={navigateToEvents} text="✨Eventos✨" />
          </MenuItem>
        </MenuList>
      </MenuContainer>
    </HeaderMenu>
  );
};

export default HamburgerMenu;
